/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityID) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityDetails: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityID
      }
    },
    blueprintArrays: [
      'GetEntityDetailsResponse.Entity.Officers.ND_EntityOfficer',
      'GetEntityDetailsResponse.Entity.Locations.ND_Location',
      'GetEntityDetailsResponse.Entity.BooksAndRecords.ND_EntitySiteRecordkeeping',
      'GetEntityDetailsResponse.Entity.DBAs.ND_EntityDBA',
      'GetEntityDetailsResponse.Entity.OfficerLicenses.ND_EntityOfficerAssociatedLicense',
      'GetEntityDetailsResponse.Entity.Attachments.ND_EntityDocument'
    ],
    blueprint: {
      entity: {
        id: '$..Entity.ID.__text',
        tin: '$..TIN.__text',
        nmlsID: '$..NMLSID.__text',
        entityOrganizationTypeId: '$..OrganizationTypeID.__text',
        entityOrganizationType: '$..OrganizationTypeName.__text',
        entityName: '$..EntityName.__text',
        isVerified: '$..IsVerified.__text',
        isForeignEntity: '$..isForeignEntity.__text',
        isOfficerConvicted: '$..IsOfficerConvicted.__text',
        isOfficerConvictedDocument: {
          fileId: '$..IsOfficerConvictedDocumentID.__text',
          fileName: '$..IsOfficerConvictedDocumentName.__text'
        },
        articlesOfIncorporationDocument: {
          fileId: '$..ArticlesOfIncorporationDocumentID.__text',
          fileName: '$..ArticlesOfIncorporationDocumentName.__text'
        },
        foreignNameRegistrationDocument: {
          fileId: '$..ForeignRegistrationStatementDocumentID.__text',
          fileName: '$..ForeignRegistrationStatementDocumentName.__text'
        },
        operatingAgreementDocument: {
          fileId: '$..OperatingAgreementDocumentID.__text',
          fileName: '$..OperatingAgreementDocumentName.__text'
        },
        byLawsDocument: {
          fileId: '$..ByLawsDocumentID.__text',
          fileName: '$..ByLawsDocumentName.__text'
        },
        webAddress: '$..WebAddress.__text',
        listWebsiteBusiness: '$..ListWebsiteBusiness.__text',
        entityName2: '$..EntityName2.__text',
        entityNameForced: '$..EntityNameForced.__text',
        documentExceptionLegalOpinion: '$..DocumentExceptionLegalOpinion.__text',
        phoneNumber: '$..PhoneNumber.__text',
        phoneNumberExt: '$..PhoneNumberExt.__text',
        secondaryPhoneNumber: '$..SecondaryPhoneNumber.__text',
        secondaryPhoneNumberExt: '$..SecondaryPhoneNumberExt.__text',
        faxNumber: '$..FaxNumber.__text',
        faxNumberExt: '$..FaxNumberExt.__text',
        incorporationDate: '$..IncorporationDate.__text',
        incorporationPlace: '$..IncorporationPlace.__text',
        agentOfService: {
          fullName: '$..AgentOfServiceFullName.__text',
          addressId: '$..AgentOfServiceAddressID.__text',
          addressLine1: '$..Entity.AddressLine1.__text',
          addressLine2: '$..Entity.AddressLine2.__text',
          addressLine3: '$..Entity.AddressLine3.__text',
          city: '$..Entity.City.__text',
          state: '$..Entity.State.__text',
          postalCode: '$..Entity.PostalCode.__text',
          country: '$..Entity.Country.__text',
          county: '$..Entity.County.__text',
          phoneNumber: '$..AgentOfServicePhoneNumber.__text'
        },
        entityOfficers: [
          '$..ND_EntityOfficer',
          {
            id: '$..ID',
            firstName: '$..FirstName',
            lastName: '$..LastName',
            middleName: '$..MiddleName',
            title: '$..Title',
            ssn: '$..SSN',
            dob: '$..DOB',
            homePhoneNumber: '$..HomePhoneNumber',
            officePhoneExt: '$..OfficePhoneExt',
            cellPhoneNumber: '$..CellPhoneNumber',
            email: '$..Email',
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country',
            county: '$..County',
            entityOfficerTypeId: '$..EntityOfficerTypeID',
            officerType: '$..EntityOfficerTypeName'
          }
        ],
        locations: [
          '$..ND_Location',
          {
            id: '$..ID',
            isPrimaryPlaceOfBusiness: '$..IsPrimaryPlaceOfBusiness',
            officePhoneNumber: '$..OfficePhoneNumber',
            officeFaxNumber: '$..OfficeFaxNumber',
            createdDate: '$..CreatedDate',
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country',
            county: '$..County'
          }
        ],
        alternateSitekeepings: [
          '$..ND_EntitySiteRecordkeeping',
          {
            id: '$..ID',
            companyName: '$..CompanyName',
            firstName: '$..FirstName',
            lastName: '$..LastName',
            phone: '$..Phone',
            email: '$..Email',
            fax: '$..Fax',
            description: '$..Description',
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country',
            county: '$..County'
          }
        ],
        dbas: [
          '$..ND_EntityDBA',
          {
            id: '$..ID',
            dbaName: '$..DBA',
            fictitiousNameDocument: {
              fileId: '$..FictitiousDocumentID',
              fileName: '$..FictitiousDocumentName'
            },
            registrationDate: '$..RegistrationDate',
            endDate: '$..EndDate'
          }
        ],
        officerLicenses: [
          '$..ND_EntityOfficerAssociatedLicense',
          {
            id: '$..ID',
            entityOfficerID: '$..EntityOfficerID',
            licenseNumber: '$..LicenseNumber',
            licenseType: '$..LicenseType',
            entityName: '$..EntityName'
          }
        ],
        attachments: [
          '$..ND_EntityDocument',
          {
            entityDocumentId: '$.ID',
            file: {
              fileId: '$.DocumentID',
              fileName: '$.Name'
            },
            documentTypeID: '$.DocumentTypeID',
            documentType: '$.DocumentType'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.entity.locations, function(location) {
        location.isPrimaryPlaceOfBusiness = location.isPrimaryPlaceOfBusiness === '1';
        location.createdDate = moment(location.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
      });

      _.each(response.entity.dbas, function(dba) {
        if (dba.registrationDate) {
          dba.registrationDate = moment(dba.registrationDate, 'YYYY-MM-DD');
        }
        if (dba.endDate) {
          dba.endDate = moment(dba.endDate, 'YYYY-MM-DD');
        }
      });

      _.each(response.entity.entityOfficers, function(officer) {
        officer.dob = officer.dob ? moment(officer.dob, moment.ISO_8601).format('MM/DD/YYYY') : '';

        officer.associatedLicenses = _.where(response.entity.officerLicenses, { entityOfficerID: officer.id });
      });

      response.entity.incorporationDate = response.entity.incorporationDate
        ? moment(response.entity.incorporationDate, moment.ISO_8601).format('MM/DD/YYYY')
        : '';

      response.entity.isVerified = response.entity.isVerified === '1';

      delete response.entity.officerLicenses;

      deferred.resolve(response.entity);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
