/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var moment = require('moment');

var UIUtil = require('psa-core/util/ui');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitAcknowledgeNotification = require('../../services/SubmitAcknowledgeNotification');

var tmplSecEntityViewNotificationTaskView = require('./SecEntityViewNotificationTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecEntityViewNotificationTaskView,

  className: 'sec-entity-view-notification-task-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    documentContainer: '.document-container',
    submit: '.submit',
    print: '.print',
    notificationContainer: '.notification-container',
    headerBar: '.header-bar-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    documentContainer: '@ui.documentContainer',
    headerBar: '@ui.headerBar'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.print': 'onClickPrint'
  },

  onBeforeRender: function() {},

  onBeforeShow: function() {
    var examId = modelGet(this.model, 'examId');
    var headerBarColumns = [];

    if (examId) {
      headerBarColumns = [
        {
          name: 'examId',
          label: 'Exam ID',
          minWidth: '160px',
          maxWidth: '200px'
        },
        {
          name: 'applicantLegalName',
          label: 'Applicant Legal Name',
          minWidth: '160px',
          maxWidth: '200px'
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          minWidth: '160px',
          maxWidth: '200px'
        },
        {
          name: 'deliveryDate',
          label: 'Notification Date/Time',
          minWidth: '160px',
          maxWidth: '200px'
        }
      ];
    } else {
      headerBarColumns = [
        {
          name: 'applicantLegalName',
          label: 'Applicant Legal Name',
          minWidth: '160px',
          maxWidth: '200px'
        },
        {
          name: 'CRDNumber',
          label: 'CRD Number',
          minWidth: '160px',
          maxWidth: '200px'
        },
        {
          name: 'deliveryDate',
          label: 'Notification Date/Time',
          minWidth: '160px',
          maxWidth: '200px'
        }
      ];
    }

    var headerbar = modelGet(this.model, 'examId')
      ? modelGet(this.model, 'examIdentifiers')
      : modelGet(this.model, 'organizationIdentifiers');
    headerbar.deliveryDate = moment(modelGet(this.model, 'task.deliveryDate'), moment.ISO_8601).format(
      'MM/DD/YYYY hh:mm a'
    );
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerbar)
      })
    );

    var taskDescription = modelGet(this.model, 'task.taskType.description');
    var documents = modelGet(this.model, 'notification.documents');
    _.each(documents, function(document) {
      document = _.extend(document, document.file);
    });

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/sec/dashboard',
            text: 'Home'
          },
          {
            text: taskDescription
          }
        ]
      })
    );

    if (documents.length) {
      this.fileUploadTable = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection(documents),
        emptyText: 'No Related Documents'
      });
      this.showChildView('documentContainer', this.fileUploadTable);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var taskId = this.model.get('taskId');
    this.validate().done(function() {
      UIUtil.showModal({
        title: 'Submit Notification',
        view: new ConfirmationModalView({
          confirmText: 'Submit',
          model: new Backbone.Model({
            message:
              'Do not submit until you have downloaded and saved the attachments. Once this notification is submitted, access to the notification and its attachments will be revoked.',
            onConfirm: function() {
              return SubmitAcknowledgeNotification(taskId);
            },
            onConfirmDone: function() {
              Radio.channel('navigate').trigger('show:ext:sec:dashboard');
            }
          })
        })
      });
    });
  },

  onClickPrint: function(e) {
    e.preventDefault();
    window.print();
  },

  validate: function() {
    return this.ui.notificationContainer.parsley().whenValidate();
  }
});
