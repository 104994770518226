/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');
var moment = require('moment');

module.exports = function(formData) {
  var defer = $.Deferred();

  var isDraft = formData.isDraft;
  var isExtEdit = formData.isExtEdit;
  var entityId = formData.entityId;
  var identification = formData.identification;
  var dbas = identification.dbas || [];
  var entityOfficersDetails = formData.entityOfficers || {};
  var entityOfficers = entityOfficersDetails.officersList || [];
  var locations = formData.locations || [];
  var booksAndRecords = formData.booksAndRecords || [];
  var attachments = formData.attachments || [];

  var formOutboundDateConfig = {
    sourceFormat: 'MM/DD/YYYY',
    targetFormat: 'YYYY-MM-DD',
    default: ''
  };
  identification.incorporationDate = momentUtil.formatStr(identification.incorporationDate, formOutboundDateConfig);

  $.soap({
    body: {
      SubmitNewEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        IsDraft: isDraft ? '1' : '0',
        IsExtEdit: isExtEdit ? '1' : '0',
        EntityID: entityId,
        EntityOrganizationTypeID: identification.entityOrganizationTypeId,
        EntityName: identification.entityName,
        EntityName2: identification.entityName2,
        EntityNameForced: identification.entityNameForced,
        PhoneNumber: identification.phoneNumber,
        PhoneNumberExt: identification.phoneNumberExt,
        SecondaryPhoneNumber: identification.secondaryPhoneNumber,
        SecondaryPhoneNumberExt: identification.secondaryPhoneNumberExt,
        FaxNumber: identification.faxNumber,
        FaxNumberExt: identification.faxNumberExt,
        IncorporationDate: identification.incorporationDate,
        IncorporationPlace: identification.incorporationPlace,
        TIN: identification.tin,
        TINTypeID: identification.tinTypeId,
        ArticlesOfIncorporationDocumentID: identification.articlesOfIncorporationDocumentId,
        OperatingAgreementDocumentID: identification.operatingAgreementDocumentId,
        ByLawsDocumentID: identification.byLawsDocumentId,
        DocumentExceptionLegalOpinion: identification.documentExceptionLegalOpinion,
        IsForeignEntity: identification.isForeignEntity,
        ForeignRegistrationStatementDocumentID: identification.foreignRegistrationStatementDocumentId,
        AgentOfService: (function() {
          var aosDetails = identification.agentOfService || {};
          return {
            AgentOfServiceFullName: aosDetails.fullName,
            AddressLine1: aosDetails.addressLine1,
            AddressLine2: aosDetails.addressLine2,
            AddressLine3: aosDetails.addressLine3,
            City: aosDetails.city,
            State: aosDetails.state,
            PostalCode: aosDetails.postalCode,
            Country: aosDetails.country,
            County: aosDetails.county,
            PhoneNumber: aosDetails.phoneNumber
          };
        })(),
        WebAddress: identification.webAddress,
        ListWebsiteBusiness: identification.listWebsiteBusiness,
        DBAs: (function() {
          if (!dbas.length) {
            return {};
          }
          return {
            DBA: _.map(dbas, function(dba) {
              return {
                FictitiousDocumentID: dba.fictitiousDocumentId,
                DBA: dba.dbaName,
                EndDate: dba.endDate,
                RegistrationDate: dba.registrationDate
              };
            })
          };
        })(),
        IsOfficerConvicted: entityOfficersDetails.isOfficerConvicted,
        IsOfficerConvictedDocumentID: entityOfficersDetails.isOfficerConvictedDocumentId,
        EntityOfficers: (function() {
          if (!entityOfficers.length) {
            return {};
          }
          return {
            EntityOfficer: _.map(entityOfficers, function(entityOfficer) {
              return {
                EntityOfficerTypeID: entityOfficer.entityOfficerTypeId,
                Title: entityOfficer.title,
                FirstName: entityOfficer.firstName,
                MiddleName: entityOfficer.middleName,
                LastName: entityOfficer.lastName,
                SSN: entityOfficer.ssn,
                DOB: entityOfficer.dob ? moment(entityOfficer.dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                HomePhoneNumber: entityOfficer.homePhoneNumber,
                OfficePhoneExt: entityOfficer.officePhoneExt,
                CellPhoneNumber: entityOfficer.cellPhoneNumber,
                Email: entityOfficer.email,
                AddressLine1: entityOfficer.addressLine1,
                AddressLine2: entityOfficer.addressLine2,
                AddressLine3: entityOfficer.addressLine3,
                City: entityOfficer.city,
                State: entityOfficer.state,
                PostalCode: entityOfficer.postalCode,
                Country: entityOfficer.country,
                County: entityOfficer.county,
                AssociatedLicenses: (function() {
                  if (!entityOfficer.associatedLicenses || !entityOfficer.associatedLicenses.length) {
                    return {};
                  }
                  return {
                    AssociatedLicense: _.map(entityOfficer.associatedLicenses, function(associatedLicense) {
                      return {
                        LicenseNumber: associatedLicense.licenseNumber,
                        EntityName: associatedLicense.entityName,
                        LicenseType: associatedLicense.licenseType
                      };
                    })
                  };
                })()
              };
            })
          };
        })(),
        Locations: (function() {
          if (!locations.length) {
            return {};
          }
          return {
            Location: _.map(locations, function(location) {
              return {
                IsPrimaryPlaceOfBusiness: location.isPrimaryPlaceOfBusiness ? '1' : '0',
                AddressLine1: location.addressLine1,
                AddressLine2: location.addressLine2,
                AddressLine3: location.addressLine3,
                City: location.city,
                State: location.state,
                PostalCode: location.postalCode,
                Country: location.country,
                County: location.county,
                OfficePhoneNumber: location.officePhoneNumber,
                OfficeFaxNumber: location.officeFaxNumber
              };
            })
          };
        })(),
        SiteRecordkeepings: (function() {
          if (!booksAndRecords.length) {
            return {};
          }
          return {
            SiteRecordkeeping: _.map(booksAndRecords, function(booksAndRecord) {
              return {
                CompanyName: booksAndRecord.companyName,
                FirstName: booksAndRecord.firstName,
                LastName: booksAndRecord.lastName,
                AddressLine1: booksAndRecord.addressLine1,
                AddressLine2: booksAndRecord.addressLine2,
                AddressLine3: booksAndRecord.addressLine3,
                City: booksAndRecord.city,
                State: booksAndRecord.state,
                PostalCode: booksAndRecord.postalCode,
                Country: booksAndRecord.country,
                County: booksAndRecord.county,
                Phone: booksAndRecord.phone,
                Fax: booksAndRecord.fax,
                Email: booksAndRecord.email,
                Description: booksAndRecord.description
              };
            })
          };
        })(),
        EntityDocuments: (function() {
          if (!attachments.length) {
            return {};
          }
          return {
            EntityDocument: _.map(attachments, function(attachment) {
              return {
                DocumentID: attachment.fileId,
                DocumentTypeID: attachment.documentTypeId
              };
            })
          };
        })(),
        DocumentsToMove: (function() {
          var attachmentDocIds = _.pluck(attachments, 'fileId');
          var dbaDocIds = _.pluck(identification.dbas, 'fictitiousDocumentId');
          var documentsToMove = _.compact(
            _.union(attachmentDocIds, dbaDocIds, [
              identification.articlesOfIncorporationDocumentId,
              identification.operatingAgreementDocumentId,
              identification.byLawsDocumentId,
              identification.foreignRegistrationStatementDocumentId,
              entityOfficersDetails.isOfficerConvictedDocumentId
            ])
          );
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })()
      }
    },
    blueprint: {
      newEntityId: '$..NewEntityID.__text'
    }
  })
    .done(function(response) {
      defer.resolve(response.newEntityId);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
