var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Entity</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.entityName : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Officer Type</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.entityOfficerTypeName : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityName","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return "--";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.entityOfficerTypeName || (depth0 != null ? depth0.entityOfficerTypeName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityOfficerTypeName","hash":{},"data":data}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstName","hash":{},"data":data}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastName","hash":{},"data":data}) : helper)));
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.middleName || (depth0 != null ? depth0.middleName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"middleName","hash":{},"data":data}) : helper)));
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)));
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.suffix || (depth0 != null ? depth0.suffix : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"suffix","hash":{},"data":data}) : helper)));
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.ssn || (depth0 != null ? depth0.ssn : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ssn","hash":{},"data":data}) : helper)));
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.dob || (depth0 != null ? depth0.dob : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dob","hash":{},"data":data}) : helper)));
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Office Phone Number</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.homePhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Ext</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.officePhoneExt : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.homePhoneNumber || (depth0 != null ? depth0.homePhoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"homePhoneNumber","hash":{},"data":data}) : helper)));
},"25":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.officePhoneExt || (depth0 != null ? depth0.officePhoneExt : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"officePhoneExt","hash":{},"data":data}) : helper)));
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Home Phone Number</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.homePhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.cellPhoneNumber || (depth0 != null ? depth0.cellPhoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cellPhoneNumber","hash":{},"data":data}) : helper)));
},"31":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)));
},"33":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.ein || (depth0 != null ? depth0.ein : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ein","hash":{},"data":data}) : helper)));
},"35":function(container,depth0,helpers,partials,data) {
    return "<div class=\"form-group m-t-md\">\n  <label class=\"control-label\">Other Associated Licenses</label>\n  <div class=\"associated-licenses-container\"></div>\n</div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "checked";
},"39":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.pspCheckCompletedDate || (depth0 != null ? depth0.pspCheckCompletedDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pspCheckCompletedDate","hash":{},"data":data}) : helper)));
},"41":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.nmlsDateCompleted || (depth0 != null ? depth0.nmlsDateCompleted : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nmlsDateCompleted","hash":{},"data":data}) : helper)));
},"43":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.nonNmlsDateCompleted || (depth0 != null ? depth0.nonNmlsDateCompleted : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nonNmlsDateCompleted","hash":{},"data":data}) : helper)));
},"45":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.jnetDateReceived || (depth0 != null ? depth0.jnetDateReceived : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"jnetDateReceived","hash":{},"data":data}) : helper)));
},"47":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.jnetTypeName || (depth0 != null ? depth0.jnetTypeName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"jnetTypeName","hash":{},"data":data}) : helper)));
},"49":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.jnetComments || (depth0 != null ? depth0.jnetComments : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"jnetComments","hash":{},"data":data}) : helper)));
},"51":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.otherDateReceived || (depth0 != null ? depth0.otherDateReceived : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"otherDateReceived","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">First Name</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.firstName : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Last Name</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lastName : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Middle Name</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.middleName : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Title</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Suffix</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.suffix : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Social Security Number</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ssn : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Date of Birth</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dob : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Cell Phone Number</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cellPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Email Address</label>\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label\">EIN\\Fed ID</label>\n  <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ein : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label\">Address</label>\n  <div class=\"address-container\"></div>\n</div>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<h3>Background Information</h3>\n<form class=\"form-horizontal\">\n  <div class=\"form-group\">\n    <div class=\"col-sm-4\">\n      <label class=\"checkbox-inline control-label\">\n        <input type=\"checkbox\" name=\"pspCheck\" id=\"pspCheck\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pspCheck : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " disabled> PSP Check Complete\n      </label>\n    </div>\n    <div class=\"col-sm-2\">\n      <label class=\"control-label\">Date Completed</label>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pspCheckCompletedDate : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-4\">\n      <label class=\"checkbox-inline control-label\">\n        <input type=\"checkbox\" name=\"nmlsCheck\" id=\"nmlsCheck\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nmlsCheck : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " disabled>NMLS Criminal\n        Background\n        Check\n      </label>\n    </div>\n    <div class=\"col-sm-2\">\n      <label class=\"control-label\">Date Completed</label>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nmlsDateCompleted : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-4\">\n      <label class=\"checkbox-inline control-label\">\n        <input type=\"checkbox\" name=\"nonNmlsCheck\" id=\"nonNmlsCheck\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nonNmlsCheck : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          disabled>Non-NMLS\n        Criminal\n        Background\n        Check\n      </label>\n    </div>\n    <div class=\"col-sm-2\">\n      <label class=\"control-label\">Date Completed</label>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nonNmlsDateCompleted : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-4\">\n      <label class=\"checkbox-inline control-label\">\n        <input type=\"checkbox\" name=\"jnetReceived\" id=\"jnetReceived\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.jnetReceived : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " disabled>JNet\n        Received\n      </label>\n    </div>\n    <div class=\"col-sm-2\">\n      <label class=\"control-label\">Date Received</label>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.jnetDateReceived : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-10\">\n      <label class=\"control-label jnet-section\">JNET Type</label>\n      <div class=\"form-control-static jnet-section\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.jnetTypeName : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-10\">\n      <label class=\"control-label jnet-section\">JNet Comments</label>\n      <p class=\"form-control-static jnet-section\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.jnetComments : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-4\">\n      <label class=\"checkbox-inline control-label\">\n        <input type=\"checkbox\" name=\"otherCheck\" id=\"otherCheck\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.otherCheck : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " disabled>Other\n      </label>\n    </div>\n    <div class=\"col-sm-2\">\n      <label class=\"control-label\">Date Received</label>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.otherDateReceived : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-4\">\n      <label class=\"checkbox-inline control-label\">\n        <input type=\"checkbox\" name=\"flagged\" id=\"flagged\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.flagged : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " disabled> Flagged/Hold\n      </label>\n    </div>\n  </div>\n</form>";
},"useData":true});