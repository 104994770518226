/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var HeaderView = require('./HeaderView');
var ExternalFooterView = require('./ExternalFooterView');

var tmplScaffoldView = require('../templates/ScaffoldView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplScaffoldView,

  el: 'body',

  ui: {
    header: '#__header',
    content: '#__content',
    footer: '#__footer'
  },

  regions: {
    header: '@ui.header',
    content: '@ui.content',
    footer: '@ui.footer'
  },

  onRender: function() {
    if (PUB) {
      this.$el.addClass('public');
      this.footer.show(new ExternalFooterView());
    } else if (EXTERNAL) {
      this.$el.addClass('external');
      this.footer.show(new ExternalFooterView());
    } else {
      this.$el.addClass('internal');
    }
    this.header.show(new HeaderView());
  }
});
