/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetInstallmentSellerRenewalApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: ['GetInstallmentSellerRenewalApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'],
    blueprint: {
      id: '$..ND_AppLicenseIntallmentSellerRenew.ID',
      applicationId: '$..ND_AppLicenseIntallmentSellerRenew.ApplicationID',
      hasCriminalOfficer: '$..HasCriminalOfficer',
      hasCriminalOfficerExplaination: '$..HasCriminalOfficerExplaination',
      hasCriminalOfficerDocument: {
        fileId: '$..HasCriminalOfficerDocumentID',
        fileName: '$..HasCriminalOfficerDocumentName'
      },
      hasOfficerWithAdministrativeAction: '$..HasOfficerWithAdministrativeAction',
      hasOfficerWithAdministrativeActionExplaination: '$..HasOfficerWithAdministrativeActionExplaination',
      hasOfficerWithAdministrativeActionDocument: {
        fileId: '$..HasOfficerWithAdministrativeActionDocumentID',
        fileName: '$..HasOfficerWithAdministrativeActionDocumentName'
      },
      hasLegalProceedings: '$..HasLegalProceedings',
      hasLegalProceedingsExplaination: '$..HasLegalProceedingsExplaination',
      hasLegalProceedingsDocument: {
        fileId: '$..HasLegalProceedingsDocumentID',
        fileName: '$..HasLegalProceedingsDocumentName'
      },
      contractsDollarValue: '$..ContractsDollarValue',
      thirdPartyDollarValue: '$..ThirdPartyDollarValue',
      reportingZeroReason: '$..ReportingZeroReason',
      contractsTotalNumber: '$..ContractsTotalNumber',
      thirdPartyTotalNumber: '$..ThirdPartyTotalNumber',
      hasContracts: '$..HasContracts',
      hasContractsExplaination: '$..HasContractsExplaination',
      hasThirdParty: '$..HasThirdParty',
      hasThirdPartyExplaination: '$..HasThirdPartyExplaination',
      hasUpdatedLicenseContacts: '$..HasUpdatedLicenseContacts',
      isReportingContracts: '$..IsReportingContracts',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified',
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isCertified = response.isCertified === '1';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
