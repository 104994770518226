/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(formData) {
  return $.soap({
    body: {
      SubmitIndividualGeneralInformation: {
        $xmlns: 'http://dobs.pa.gov/nd/individual/bpm/1.0',
        IndividualID: formData.individualId,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        MiddleName: formData.middleName,
        Title: formData.title,
        Suffix: formData.suffix,
        SSN: formData.ssn,
        DOB: formData.dob,
        EIN: formData.ein,
        HomePhoneNumber: formData.homePhoneNumber,
        OfficePhoneExt: formData.officePhoneExt,
        CellPhoneNumber: formData.cellPhoneNumber,
        Email: formData.email,
        AddressLine1: formData.addressLine1,
        AddressLine2: formData.addressLine2,
        AddressLine3: formData.addressLine3,
        City: formData.city,
        State: formData.state,
        PostalCode: formData.postalCode,
        Country: formData.country,
        County: formData.county,
        PSPCheck: formData.pspCheck,
        PSPCheckCompletedDate: formData.pspCheckCompletedDate,
        NMLSCheck: formData.nmlsCheck,
        NMLSDateCompleted: formData.nmlsDateCompleted,
        NonNMLSCheck: formData.nonNmlsCheck,
        NonNMLSDateCompleted: formData.nonNmlsDateCompleted,
        JNetReceived: formData.jnetReceived,
        JNetDateReceived: formData.jnetDateReceived,
        JNetComments: formData.jnetComments,
        OtherCheck: formData.otherCheck,
        OtherDateReceived: formData.otherDateReceived,
        JNETType: formData.jnetType,
        Flagged: formData.flagged
      }
    }
  });
};
