/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityHCExamsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityHCExamsByEntityResponse.Exams.DP_EntityExam',
      'GetEntityHCExamsByEntityResponse.ExamAreas.DP_ExamArea',
      'GetEntityHCExamsByEntityResponse.ExamConductedBys.DP_ExamConductedBy'
    ],
    blueprint: {
      exams: [
        '$..DP_EntityExam',
        {
          id: '$.ID',
          entityId: '$.EntityID',
          statusId: '$.StatusID',
          bhcAsOfDate: '$.BHCAsOfDate',
          riskManagement: '$.HCRatingRisk',
          composite: '$.ComplianceRatingComposite',
          transmittalDate: '$.TransmittalDate',
          hcComposite: '$.HCRatingComposite'
        }
      ],
      examAreas: [
        '$..DP_ExamArea',
        {
          id: '$..ID',
          examId: '$..ExamID',
          examAreaId: '$..ExamAreaID',
          examArea: '$..ExamArea',
          asOfDate: '$..AsOfDate'
        }
      ],
      examConductedBys: [
        '$..DP_ExamConductedBy',
        {
          id: '$..ID',
          examId: '$..ExamID',
          conductedBy: '$..ConductedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var transExams = [];

      var getExamArea = function(areaId) {
        switch (areaId) {
          case '1':
          case '11':
            return 'S&S';
          case '2':
          case '21':
            return 'Trust';
          case '3':
          case '12':
          case '22':
            return 'IT';
          default:
            return '';
        }
      };

      _.each(response.exams, function(exam) {
        exam.asOfDate = _.reduce(
          response.examAreas,
          function(memo, area) {
            if (area.examId === exam.id) {
              var asOfDateAreaIds = ['1', '2', '3', '11', '12', '21', '22'];
              if (_.contains(asOfDateAreaIds, area.areaId)) {
                memo.push({
                  label: getExamArea(area.examAreaId),
                  value: momentUtil.formatStr(area.asOfDate, momentUtil.defaultInboundDateConfig)
                });
              }
            }
            return memo;
          },
          []
        );

        exam.conductedBy = _.reduce(
          response.examConductedBys,
          function(memo, conductedBy) {
            if (conductedBy.examId === exam.id) {
              memo.push({
                value: conductedBy.conductedBy
              });
            }
            return memo;
          },
          []
        );

        exam.riskManagement = exam.riskManagement || '--';
        exam.composite = exam.composite || '--';
        exam.hcComposite = exam.hcComposite || '--';

        
        if (exam.transmittalDate !== null) {
          transExams.push(exam)
        }
      });

      response.exams = transExams

      defer.resolve(response.exams);
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
