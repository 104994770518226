/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplInstallmentSellerRenewalGeneralDetailsSectionView = require('./InstallmentSellerRenewalGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInstallmentSellerRenewalGeneralDetailsSectionView,

  className: 'installment-seller-renewal-general-details-section-view',

  ui: {
    criminalOfficerFileContainer: '.criminal-officer-file-container',
    administrativeActionFileContainer: '.administrative-action-file-container',
    generalDetailsForm: '.general-details-form',
    legalProceedingsFileContainer: '.legal-proceedings-file-container',
    applicationDocumentsFileContainer: '.application-documents-container'
  },

  regions: {
    criminalOfficerFileContainer: '@ui.criminalOfficerFileContainer',
    administrativeActionFileContainer: '@ui.administrativeActionFileContainer',
    legalProceedingsFileContainer: '@ui.legalProceedingsFileContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasUpdatedLicenseContactsLinkArea',
          radioGroup: '[name="hasUpdatedLicenseContacts"]',
          valueToShow: '1'
        },
        {
          target: '#hasCriminalOfficerExplainationArea',
          radioGroup: '[name="hasCriminalOfficer"]',
          valueToShow: '1'
        },
        {
          target: '#hasOfficerWithAdministrativeActionExplainationArea',
          radioGroup: '[name="hasOfficerWithAdministrativeAction"]',
          valueToShow: '1'
        },
        {
          target: '#hasLegalProceedingsExplainationArea',
          radioGroup: '[name="hasLegalProceedings"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var hasCriminalOfficerDocument = this.model.get('renewalData').hasCriminalOfficerDocument;

    this.criminalOfficerFile = new FileUploadView({
      collection: makeUploadCollection(hasCriminalOfficerDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('criminalOfficerFileContainer', this.criminalOfficerFile);

    var hasOfficerWithAdministrativeActionDocument = this.model.get('renewalData')
      .hasOfficerWithAdministrativeActionDocument;

    this.administrativeActionFile = new FileUploadView({
      collection: makeUploadCollection(hasOfficerWithAdministrativeActionDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('administrativeActionFileContainer', this.administrativeActionFile);

    var hasLegalProceedingsDocument = this.model.get('renewalData').hasLegalProceedingsDocument;

    this.legalProceedingsFile = new FileUploadView({
      collection: makeUploadCollection(hasLegalProceedingsDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('legalProceedingsFileContainer', this.legalProceedingsFile);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('renewalData').applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    Syphon.deserialize(this, this.model.get('renewalData'));
  },

  validate: function(config) {
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }
    return $.when(this.ui.generalDetailsForm.parsley().whenValidate());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    _.extend(formData, {
      hasCriminalOfficerDocumentId: this.criminalOfficerFile.getFirstFileId(),
      hasOfficerWithAdministrativeActionDocumentId: this.administrativeActionFile.getFirstFileId(),
      hasLegalProceedingsDocumentId: this.legalProceedingsFile.getFirstFileId(),
      applicationDocuments: this.applicationDocumentsFile.collection.pluck('fileId')
    });
    return formData;
  }
});
