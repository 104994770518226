/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var moment = require('moment');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityOfficerChangeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetEntityOfficerChangeApplicationResponse.ND_AppEntityOfficerChange.EntityOfficer.AssociatedLicenses.ND_EntityOfficerAssociatedLicense',
      'GetEntityOfficerChangeApplicationResponse.ND_AppEntityOfficerChange.EntityOfficer.AssociatedLicenses.ND_AppEntityOfficerAssociatedLicense',
      'GetEntityOfficerChangeApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'
    ],
    blueprint: {
      applicationId: '$..ApplicationID.__text',
      entityId: '$..EntityID.__text',
      entityOfficerId: '$..EntityOfficerID.__text',
      individualId: '$..IndividualID.__text',
      isDeleted: '$..IsDeleted.__text',
      isOfficerConvicted: '$..IsOfficerConvicted.__text',
      isOfficerConvictedDocument: {
        fileId: '$..IsOfficerConvictedDocumentID.__text',
        fileName: '$..IsOfficerConvictedDocumentName.__text'
      },
      effectiveChangeDate: '$..EffectiveChangeDate.__text',
      id: '$..ID.__text',
      firstName: '$..FirstName.__text',
      lastName: '$..LastName.__text',
      middleName: '$..MiddleName.__text',
      title: '$..Title.__text',
      ssn: '$..SSN.__text',
      dob: '$..DOB.__text',
      ein: '$..EIN.__text',
      homePhoneNumber: '$..HomePhoneNumber.__text',
      officePhoneExt: '$..OfficePhoneExt.__text',
      cellPhoneNumber: '$..CellPhoneNumber.__text',
      email: '$..Email.__text',
      addressId: '$..AddressID.__text',
      addressLine1: '$..AddressLine1.__text',
      addressLine2: '$..AddressLine2.__text',
      addressLine3: '$..AddressLine3.__text',
      city: '$..City.__text',
      state: '$..State.__text',
      postalCode: '$..PostalCode.__text',
      country: '$..Country.__text',
      county: '$..County.__text',
      entityOfficerTypeId: '$..EntityOfficerTypeID.__text',
      officerType: '$..EntityOfficerTypeName.__text',
      certificationName: '$..CertificationName.__text',
      isCertified: '$..IsCertified.__text',
      entityAssociatedLicenses: [
        '$..ND_EntityOfficerAssociatedLicense',
        {
          licenseNumber: '$..LicenseNumber',
          licenseType: '$..LicenseType',
          entityName: '$..EntityName'
        }
      ],
      associatedLicenses: [
        '$..ND_AppEntityOfficerAssociatedLicense',
        {
          id: '$..AssociatedLicenseID',
          licenseNumber: '$..LicenseNumber',
          licenseType: '$..LicenseType',
          entityName: '$..EntityName',
          isDeleted: '$..IsDeleted'
        }
      ],
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isCertified = response.isCertified === '1';
      response.effectiveChangeDate = moment(response.effectiveChangeDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      response.isDeleted = response.isDeleted === '1';
      if (response.dob) {
        response.dob = moment(response.dob, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
