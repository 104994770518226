/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var generalDetails = formData.generalDetails;
  var certifyCapital = formData.certifyCapital;
  var branchLicenses = formData.branchLicenses || [];
  var affirmation = formData.affirmation;

  return $.soap({
    body: {
      SubmitConsumerDiscountRenewalApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: formData.applicationId,
        HasCriminalOfficer: generalDetails.hasCriminalOfficer,
        HasCriminalOfficerExplaination: generalDetails.hasCriminalOfficerExplaination,
        HasCriminalOfficerDocumentID: generalDetails.hasCriminalOfficerDocumentId,
        HasOfficerWithAdministrativeAction: generalDetails.hasOfficerWithAdministrativeAction,
        HasOfficerWithAdministrativeActionExplaination: generalDetails.hasOfficerWithAdministrativeActionExplaination,
        HasOfficerWithAdministrativeActionDocumentID: generalDetails.hasOfficerWithAdministrativeActionDocumentId,
        HasLegalProceedings: generalDetails.hasLegalProceedings,
        HasUpdatedLicenseContacts: generalDetails.hasUpdatedLicenseContacts,
        HasLegalProceedingsExplaination: generalDetails.hasLegalProceedingsExplaination,
        HasLegalProceedingsDocumentID: generalDetails.hasLegalProceedingsDocumentId,
        BondCompanyID: generalDetails.bondCompanyId,
        BondNumber: generalDetails.bondNumber,
        BondDocumentID: generalDetails.bondDocumentId,
        CommonStockAmount: certifyCapital.commonStockAmount,
        PreferredStockAmount: certifyCapital.preferredStockAmount,
        PaidInSurplusAmount: certifyCapital.paidInSurplusAmount,
        RetainedEarningsAmount: certifyCapital.retainedEarningsAmount,
        OtherAmount: certifyCapital.otherAmount,
        TotalStockholdersAmount: certifyCapital.totalStockholdersAmount,
        OtherAmountDescription: certifyCapital.otherAmountDescription,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        BranchLicenses: (function() {
          if (!branchLicenses.length) {
            return {};
          }
          return {
            BranchLicense: _.map(branchLicenses, function(branchLicense) {
              return {
                BranchLicenseID: branchLicense.id,
                IsRenewing: branchLicense.isRenewing ? '1' : '0',
                BondCompanyID: branchLicense.bondCompanyId,
                BondNumber: branchLicense.bondNumber
              };
            })
          };
        })(),
        ApplicationDocuments: (function() {
          if (generalDetails.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: generalDetails.applicationDocuments
          };
        })(),
        DocumentsToMove: (function() {
          var documentsToMove = _.compact(
            [
              generalDetails.hasCriminalOfficerDocumentId,
              generalDetails.hasOfficerWithAdministrativeActionDocumentId,
              generalDetails.bondDocumentId
            ].concat(generalDetails.applicationDocuments)
          );
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: documentsToMove
          };
        })()
      }
    }
  });
};
