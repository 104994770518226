/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');
var _ = require('underscore');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetExamDetails = require('../../../exam-dashboard/services/GetExamDetails');
var GetAllExamsLookups = require('../../../exam-dashboard/services/GetAllExamsLookups');
var GetExamDocumentsByDocumentType = require('../../../exam-dashboard/services/GetExamDocumentsByDocumentType');

var DepReviewExamResponseView = require('./DepReviewExamResponseView');

module.exports = {
  event: 'build:dp:review-exam-response',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = task.taskData.DP.dpExamId;
    $.when(
      GetExamDetails(examId),
      GetAllExamsLookups(),
      GetDocumentTypesByDepartment('DEPOSITORY'),
      GetExamDocumentsByDocumentType(examId, 20010),
      GetExamDocumentsByDocumentType(examId, 20011)
    )
      .done(function(details, lookups, documentTypes, intDocuments, extDocuments) {
        NProgress.set(0.85);

        // Filtering the documentTypes array
        var filteredDocumentTypes = _.filter(documentTypes, function(documentType) {
          return documentType.value === '20010' || documentType.value === '20003';
        });

        defer.resolve(
          new DepReviewExamResponseView({
            model: new Backbone.Model({
              examDetails: details,
              task: task,
              taskId: task.taskId,
              examId: examId,
              examActions: lookups.examActions,
              documentTypes: filteredDocumentTypes,
              intDocuments: intDocuments,
              extDocuments: extDocuments
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
