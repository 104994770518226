var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.primaryExaminerUserId : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"form-control-static secondary-examiners-list col-sm-9\">\n      <ul class=\"list-unstyled\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.secondaryExaminers : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n    </div>\n    ";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<li>"
    + container.escapeExpression(((helper = (helper = helpers.examinerUserId || (depth0 != null ? depth0.examinerUserId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"examinerUserId","hash":{},"data":data}) : helper)))
    + "</li>";
},"8":function(container,depth0,helpers,partials,data) {
    return "<div class=\"form-control-static col-sm-9\">--</div>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.region : stack1), depth0));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examContactFirstName : stack1), depth0))
    + "\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examContactLastName : stack1), depth0));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examLocation : stack1), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    return "";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"display","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<form class=\"form-horizontal choose-examiners-form col-sm-9 col-sm-offset-1\">\n  <div class=\"row\">\n    <div class=\"col-sm-9 col-sm-offset-3\">\n      <h3>Exam Team</h2>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Primary Examiner</label>\n    <div class=\"form-control-static col-sm-9\">\n      "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.primaryExaminerUserId : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Secondary Examiner(s)</label>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.secondaryExaminers : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Region</label>\n    <div class=\"form-control-static col-sm-9\">\n      "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.region : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Entity Exam Contact</label>\n    <div class=\"form-control-static col-sm-9\">"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examContactId : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Exam Location</label>\n    <div class=\"form-control-static col-sm-9\">\n      "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examLocation : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-9 col-sm-offset-3\">\n      <h3>Modify Examiners</h2>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Choose Primary Examiner</label>\n    <div class=\"col-sm-9\">\n      <select name=\"primaryExaminer\" id=\"primaryExaminer\" class=\"form-control\" data-placeholder=\"--\"\n        data-parsley-required=\"true\">\n        <option></option>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.examiners : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Choose Secondary Examiners</label>\n    <div class=\"col-sm-9\">\n      <select name=\"secondaryExaminers\" id=\"secondaryExaminers\" class=\"form-control\" data-placeholder=\"--\"\n        multiple=\"multiple\">\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.examiners : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Choose Region</label>\n    <div class=\"col-sm-9\">\n      <select name=\"region\" id=\"region\" class=\"form-control\" data-placeholder=\"--\"\n        data-parsley-required=\"true\">\n        <option></option>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.regions : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n</form>\n\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\n  <div class=\"row m-b-lg\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <a href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\" class=\"btn btn-default\">Cancel</a>\n          <button class=\"btn btn-primary btn-submit submit\">Submit</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});