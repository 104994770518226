/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var DropdownView = require('common/views/DropdownView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var userHasRole = require('common/util/userHasRole');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var CancelApplication = require('../services/CancelApplication');

var NonDepInternalApplicationGeneralInformationSectionView = require('./NonDepInternalApplicationGeneralInformationSectionView');
var NonDepInternalApplicationNotesSectionView = require('./NonDepInternalApplicationNotesSectionView');
var NonDepInternalApplicationAuditLogSectionView = require('./NonDepInternalApplicationAuditLogSectionView');
var NonDepInternalApplicationDetailsView = require('./NonDepInternalApplicationDetailsView');
var NonDepUpdateApplicationStatusModalView = require('./NonDepUpdateApplicationStatusModalView');

require('../styles/NonDepInternalApplicationView.css');
var tmplNonDepInternalApplicationView = require('../templates/NonDepInternalApplicationView.hbs');
var ApplicationReviewProcess = require('../services/ApplicationReviewProcess');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalApplicationView,

  className: 'non-dep-internal-application-view container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    applicationWrapper: '.application-wrapper',
    applicationActionDropDown: '.dropdown-container',
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    csLinkContainer: '@ui.csLinkContainer',
    applicationWrapper: '@ui.applicationWrapper',
    applicationActionDropDown: '@ui.applicationActionDropDown',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var isNMLSEntity = this.model.get('isNMLSEntity');
    var applicationId = this.model.get('applicationId');
    var isDroppedOut = this.model.get('isDroppedOut') === '1';
    var inProgressStatus = ['2', '3', '4', '5', '13', '14', '16'];
    var isInProgress = inProgressStatus.includes(this.model.get('applicationStatusId'));
    if (isNMLSEntity) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    var headerData = {
      title: 'Application',
      applicationType: this.model.get('applicationType'),
      status: this.model.get('applicationStatus'),
      owner: this.model.get('owner')
    };

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Application Folder',
          contentServerId: this.model.get('CSFolderId')
        })
      })
    );

    var dropDownOptions = [
      {
        text: 'Initiate Correspondence',
        link: '#nondep/application/' + this.model.get('applicationId') + '/initiate-correspondence'
      }
    ];

    if (isInProgress || isDroppedOut) {
      dropDownOptions = dropDownOptions.concat([
        {
          text: 'Generate Delinquency Letter',
          link: '#nondep/application/' + this.model.get('applicationId') + '/delinquency-letter'
        }
      ]);
    }

    if (
      userHasRole('ND - Licensing Chief') ||
      userHasRole('ND - Investigation Manager') ||
      userHasRole('ND - Application Buckets')
    ) {
      dropDownOptions = dropDownOptions.concat([
        {
          text: 'Update Application Status',
          onClick: this.onClickUpdateApplicationStatus.bind(this)
        }
      ]);
      if (!isDroppedOut && isInProgress) {
        dropDownOptions = dropDownOptions.concat([
          {
            isDivider: true
          },
          {
            text: 'Drop Out of Application Review Process',
            onClick: this.onClickCancel.bind(this)
          }
        ]);
      }
      if (isDroppedOut) {
        dropDownOptions = dropDownOptions.concat([
          {
            isDivider: true
          },
          {
            text: 'Resume AO1 Review',
            onClick: this.onClickResume.bind(this)
          },
          {
            text: 'Update Workflow Status',
            link: '#nondep/application/' + applicationId + '/update-status'
          }
        ]);
      }
    }

    this.showChildView(
      'applicationActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Application Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'applicationType',
            label: 'Application Type',
            minWidth: '160px',
            maxWidth: '300px'
          },
          {
            name: 'owner',
            label: 'Owner',
            minWidth: '160px'
          },
          {
            name: 'status',
            label: 'Status',
            minWidth: '160px',
            maxWidth: '350px',
            overflow: 'truncate'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.application = new SideNavSectionView({
      useNavigationButtons: this.options.isReadOnly,
      collection: new Backbone.Collection([
        {
          id: 'generalInformation',
          title: 'General Information',
          view: new NonDepInternalApplicationGeneralInformationSectionView({
            model: this.model
          })
        },
        {
          id: 'applicationDetails',
          title: 'Application Details',
          view: new NonDepInternalApplicationDetailsView({
            model: new Backbone.Model({
              applicationId: applicationId
            }),
            applicationDetailsView: this.model.get('applicationDetailsSectionView')
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: new NonDepInternalApplicationNotesSectionView({
            model: this.model
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: new NonDepInternalApplicationAuditLogSectionView({
            model: this.model
          })
        }
      ])
    });
    this.showChildView('applicationWrapper', this.application);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + this.model.get('entityId') + '/dashboard',
            text: this.model.get('entityName')
          },
          {
            text: 'Application #' + this.model.get('applicationId') + ' - ' + this.model.get('applicationType')
          }
        ]
      })
    );
  },

  onClickUpdateApplicationStatus: function(e, model) {
    e.preventDefault();
    var reportingStatusLookups = this.model.get('reportingStatusLookups');
    var applicationId = this.model.get('applicationId');
    var reportingStatusId = this.model.get('reportingStatusId');

    UIUtil.showModal({
      title: 'Update Application Status',
      view: new NonDepUpdateApplicationStatusModalView({
        model: new Backbone.Model({
          applicationId: applicationId,
          reportingStatusId: reportingStatusId,
          applicationReportingStatus: reportingStatusLookups
        })
      })
    }).done(function() {
      Radio.channel('navigate').trigger('show:int:nd:application-dashboard', applicationId);
    });
  },

  onClickCancel: function(e) {
    e.preventDefault();

    var self = this;
    var message = 'Are you sure you want to Drop Out of the Application Review Process?';
    UIUtil.showModal({
      title: 'Confirm Drop Out',
      view: new ConfirmationModalView({
        confirmText: 'Confirm Drop Out',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var applicationId = self.model.get('applicationId');
            return CancelApplication({ applicationId: applicationId });
          },
          onConfirmDone: function() {
            setTimeout(function() {
              Backbone.history.loadUrl();
            }, 1500);
          }
        })
      })
    });
  },

  onClickResume: function(e) {
    e.preventDefault();

    var self = this;
    var message = 'Are you sure you want to Resume AO1 Review?';
    UIUtil.showModal({
      title: 'Confirm Resume AO1 Review',
      view: new ConfirmationModalView({
        confirmText: 'Resume AO1 Review',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var applicationId = self.model.get('applicationId');
            var isDroppedOut = self.model.get('isDroppedOut');
            return ApplicationReviewProcess({ applicationId: applicationId, isDroppedOut: isDroppedOut });
          },
          onConfirmDone: function() {
            setTimeout(function() {
              Backbone.history.loadUrl();
            }, 1500);
          }
        })
      })
    });
  }
});
