/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(applicationData) {
  applicationData.effectiveChangeDate = moment(applicationData.effectiveChangeDate, 'MM/DD/YYYY').isValid
    ? moment(applicationData.effectiveChangeDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  applicationData.dob =
    applicationData.dob && moment(applicationData.dob, 'MM/DD/YYYY').isValid
      ? moment(applicationData.dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';

  var deferred = $.Deferred();
  $.soap({
    body: {
      SubmitEntityOfficerChangeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        EntityID: applicationData.entityId,
        ApplicationID: applicationData.applicationId,
        IsCertified: applicationData.isCertified ? '1' : '0',
        CertificationName: applicationData.certificationName,
        IsNewOfficer: applicationData.isNewOfficer,
        IsDeleted: applicationData.isDeleted,
        EffectiveChangeDate: applicationData.effectiveChangeDate,
        IsOfficerConvicted: applicationData.isOfficerConvicted,
        IsOfficerConvictedDocumentID: applicationData.isOfficerConvictedDocumentId,
        ApplicationDocuments: (function() {
          if (applicationData.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: applicationData.applicationDocuments
          };
        })(),
        EntityOfficer: {
          ID: applicationData.entityOfficerId,
          EntityOfficerTypeID: applicationData.entityOfficerTypeId,
          Title: applicationData.title,
          FirstName: applicationData.firstName,
          LastName: applicationData.lastName,
          MiddleName: applicationData.middleName,
          SSN: applicationData.ssn,
          DOB: applicationData.dob,
          EIN: applicationData.ein,
          HomePhoneNumber: applicationData.homePhoneNumber,
          OfficePhoneExt: applicationData.officePhoneExt,
          CellPhoneNumber: applicationData.cellPhoneNumber,
          Email: applicationData.email,
          Address: {
            AddressLine1: applicationData.addressLine1,
            AddressLine2: applicationData.addressLine2,
            AddressLine3: applicationData.addressLine3,
            City: applicationData.city,
            State: applicationData.state,
            County: applicationData.county,
            PostalCode: applicationData.postalCode,
            Country: applicationData.country
          },
          AssociatedLicenses: (function() {
            if (!applicationData.associatedLicenses.length) {
              return {};
            }
            return {
              AssociatedLicense: _.map(applicationData.associatedLicenses, function(associatedLicense) {
                return {
                  AssociatedLicenseID: associatedLicense.associatedLicenseId,
                  LicenseNumber: associatedLicense.licenseNumber,
                  EntityName: associatedLicense.entityName,
                  LicenseType: associatedLicense.licenseType,
                  IsDeleted: associatedLicense.isDeleted
                };
              })
            };
          })()
        }
      }
    }
  })
    .done(function() {
      deferred.resolve();
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
