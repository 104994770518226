var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"form-group\">\n    <label class=\"control-label long-question\">\n      The Department of Banking and Securities requires all applicants to provide\n      both National Criminal History Record Information (fingerprint cards) and\n      Pennsylvania Criminal Record Checks for all officers, directors, owners,\n      partners or members and any managers as per Section 6217(a)(3) of the\n      Consumer Credit Code. These forms must be completed along with license\n      applications. It is also required for all owners, partners, corporations,\n      managers, and officers.\n      <a\n        href=\"https://www.pa.gov/content/dam/copapwp-pagov/en/dobs/documents/non-bank-licensees/forms/non-bank%20licensee-criminal%20record%20check%20and%20fingerprint%20check.pdf\">Criminal\n        Record Check and Fingerprint Check (PDF)</a> These forms must be completed\n      along with license applications. It is also required for all owners, partners,\n      corporations, managers, and officers.\n    </label>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"col-sm-6\">\n      <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <label class=\"control-label\">Officer Type</label>\n        <select name=\"entityOfficerTypeId\" class=\"form-control\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.officerTypes : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-check m-t-lg\">\n        <input type=\"checkbox\" class=\"form-check-input remove-officer-checkbox\" name=\"isDeleted\" value=\"1\" />\n        <label class=\"form-check-label remove-officer-label\">Officer is being removed</label>\n      </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " required ";
},"6":function(container,depth0,helpers,partials,data) {
    return " data-parsley-required=\"true\"\n          ";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">EIN/Fed ID</label>\n        <input type=\"text\" name=\"ein\" class=\"form-control ein\" placeholder=\"EIN/Fed ID\" data-parsley-maxlength=\"20\" />\n      </div>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.middleName : stack1), depth0))
    + "</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "\n          data-parsley-required=\"true\" ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.title : stack1), depth0))
    + "</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.suffix : stack1), depth0))
    + "</div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return " data-parsley-required=\"true\" ";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.ssn : stack1), depth0))
    + "</div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "\n            data-parsley-required=\"true\" ";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.dobDisplay : stack1), depth0))
    + "</div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Office Phone Number</label>\n        <input type=\"text\" name=\"homePhoneNumber\" class=\"form-control phone-format\" placeholder=\"Office Phone Number\"\n          data-parsley-maxlength=\"20\" />\n        <p class=\"input-label\"><i class=\"fa fa-info-circle\"></i> Phone numbers cannot be call center numbers</p>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.homePhoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Ext</label>\n        <input type=\"text\" name=\"officePhoneExt\" class=\"form-control\" placeholder=\"Phone Number Ext\"\n          value=\""
    + container.escapeExpression(((helper = (helper = helpers.officePhoneExt || (depth0 != null ? depth0.officePhoneExt : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"officePhoneExt","hash":{},"data":data}) : helper)))
    + "\" data-parsley-maxlength=\"20\" />\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.homePhoneNumber : stack1), depth0))
    + "</div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Home Phone Number</label>\n        <input type=\"text\" name=\"homePhoneNumber\" class=\"form-control phone-format\" placeholder=\"Home Phone Number\"\n          data-parsley-maxlength=\"20\" />\n        <p class=\"input-label\"><i class=\"fa fa-info-circle\"></i> Phone numbers cannot be call center numbers</p>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.homePhoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return " required";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.cellPhoneNumber : stack1), depth0))
    + "</div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "\n      data-parsley-required=\"true\" ";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldIndividual : depth0)) != null ? stack1.email : stack1), depth0))
    + "</div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\">\n    <div class=\"col-sm-12 -m-t-md\">\n      <div class=\"input-label red-text\"><b>Previously: </b>\n        <div class=\"old-address\"></div>\n      </div>\n    </div>\n  </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\">\n    <div class=\"associated-licenses-instructions form-group\">\n      <div class=\"col-sm-12\">\n        <strong>Other Associated Department of Banking and Securities Licenses</strong>\n      </div>\n      <div class=\"col-sm-12\">\n        <button class=\"btn btn-default btn-md add-associated-license m-t-md m-b-md\">Add Associated License</button>\n      </div>\n    </div>\n  </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-4\">\n      <label class=\"control-label\">Business Name</label>\n    </div>\n    <div class=\"col-sm-4\">\n      <label class=\"control-label\">License Type</label>\n    </div>\n    <div class=\"col-sm-3\">\n      <label class=\"control-label\">License Number</label>\n    </div>\n  </div>\n  <div class=\"associated-licenses-collection-container\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<form class=\"non-dep-individual-form\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCbc : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"row\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"row\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"col-sm-6\">\n      <div class=\"form-group required\">\n        <label id=\"first-name-label\" class=\"control-label\">First Name</label>\n        <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\" data-parsley-required=\"true\"\n          data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.firstName : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group required last-name-section\">\n        <label class=\"control-label\">Last Name</label>\n        <input type=\"text\" name=\"lastName\" class=\"form-control last-name\" placeholder=\"Last Name\"\n          data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.lastName : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group middle-name-section\">\n        <label class=\"control-label\">Middle Name</label>\n        <input type=\"text\" name=\"middleName\" class=\"form-control\" placeholder=\"Middle Name\"\n          data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.middleName : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <label class=\"control-label\">Title</label>\n        <input type=\"text\" name=\"title\" class=\"form-control\" placeholder=\"Title\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Suffix</label>\n        <input type=\"text\" name=\"suffix\" class=\"form-control\" placeholder=\"Suffix\" data-parsley-maxlength=\"50\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.suffix : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ssn-section\">\n        <label class=\"control-label\">Social Security Number</label>\n        <input type=\"text\" name=\"ssn\" class=\"form-control ssn\" placeholder=\"Social Security Number\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-parsley-ssn=\"true\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.ssn : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " date-of-birth-section\">\n        <label class=\"control-label\">Date of Birth</label>\n        <div class=\"input-group\">\n          <input type=\"text\" name=\"dob\" class=\"form-control datepicker date-of-birth\" placeholder=\"Date Of Birth\"\n            data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n            data-parsley-errors-container=\"#dobErrorsContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"dobErrorsContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.dob : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "    <div class=\"col-sm-6\">\n      <div class=\"form-group "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <label class=\"control-label\">Cell Phone Number</label>\n        <input type=\"text\" name=\"cellPhoneNumber\" class=\"form-control phone-format\" placeholder=\"Cell Phone Number\"\n          "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-parsley-maxlength=\"20\" />\n        <p class=\"input-label\"><i class=\"fa fa-info-circle\"></i> Phone numbers cannot be call center numbers</p>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.cellPhoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <label class=\"control-label\">Email Address</label>\n    <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullProfileRequired : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-parsley-maxlength=\"254\" data-parsley-type=\"email\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"address\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.address : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.individualType : depth0),"===","Entity Officer",{"name":"ifCond","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});