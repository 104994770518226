/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityOfficers: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityOfficersResponse.Officers.ND_EntityOfficer',
      'GetEntityOfficersResponse.AssociatedLicenses.ND_EntityOfficerAssociatedLicense'
    ],
    blueprint: {
      officers: [
        '$..ND_EntityOfficer',
        {
          id: '$.ID',
          entityOfficerId: '$.ID',
          entityId: '$.EntityID',
          individualId: '$.IndividualID',
          firstName: '$.FirstName',
          lastName: '$.LastName',
          title: '$.Title',
          ssn: '$.SSN',
          dob: '$.DOB',
          homePhoneNumber: '$.HomePhoneNumber',
          officePhoneExt: '$..OfficePhoneExt',
          cellPhoneNumber: '$.CellPhoneNumber',
          email: '$.Email',
          address: {
            id: '$.AddressID',
            addressLine1: '$.AddressLine1',
            addressLine2: '$.AddressLine2',
            addressLine3: '$.AddressLine3',
            city: '$.City',
            state: '$.State',
            postalCode: '$.PostalCode',
            country: '$.Country'
          },
          officerType: '$.EntityOfficerTypeName',
          entityOfficerTypeId: '$.EntityOfficerTypeID'
        }
      ],
      officersAssociatedLicenses: [
        '$..ND_EntityOfficerAssociatedLicense',
        {
          id: '$.ID',
          entityOfficerId: '$.EntityOfficerID',
          licenseNumber: '$..LicenseNumber',
          licenseType: '$..LicenseType',
          entityName: '$..EntityName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.officers, function(officer) {
        officer.dob = momentUtil.formatStr(officer.dob, {
          sourceFormat: 'YYYY-MM-DD',
          targetFormat: 'MM/DD/YYYY',
          default: ''
        });
        officer.associatedLicenses = _.where(response.officersAssociatedLicenses, { entityOfficerId: officer.id });
      });

      deferred.resolve(response.officers);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
