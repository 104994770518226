/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var modelGet = require('common/util/modelGet');
var AddressDisplayView = require('common/views/AddressDisplayView');
var MomentCell = require('common/backgrid/MomentCell');
var toggleForm = require('common/util/toggleForm');

var getEntityMainHiddenFields = require('../util/getEntityMainHiddenFields');

var tmplDepEntityMainSectionView = require('../templates/DepEntityMainSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityMainSectionView,

  className: 'dep-entity-main-section-view',

  ui: {
    ppobAddress: '.ppob-address',
    mailingAddress: '.mailing-address',
    isMailingSame: '.isMailingSame',
    billingAddress: '.billing-address',
    isBillingSame: '.isBillingSame',
    dbas: '#dbas',
    isCollectiveInvestmentFund: '#isCollectiveInvestmentFund',
    ppobLabel: '.ppob-label',
    corpAddressLabel: '.corp-address-label',
    physAddressLabel: '.phys-address-label',
    terminationInfo: '.termination-info',
    isFRSOCCFDIC: '#isFRSOCCFDIC',
    mainPhoneNumberSection: '.mainPhoneNumberSection',
    ssEnforcementsList: '#ssEnforcementsList',
    trustEnforcementsList: '#trustEnforcementsList',
    complianceEnforcementsList: '#complianceEnforcementsList',
    craEnforcementsList: '#craEnforcementsList',
    itEnforcementsList: '#itEnforcementsList',
    nonTSPExamInfo: '.non-tsp-exam',
    tspExamInfo: '.tsp-exam-info',
    enforcementActionSection: '#enforcementActionSection',
    tspEnforcementAction: '#isTSPEnforcementAction',
    nonBankHCExamSummarySection: '.exam-details',
    bankHCExamSummarySection: '.exam-details-bank-holding-company'
  },

  regions: {
    ppobAddress: '@ui.ppobAddress',
    mailingAddress: '@ui.mailingAddress',
    billingAddress: '@ui.billingAddress',
    ssEnforcementsList: '@ui.ssEnforcementsList',
    trustEnforcementsList: '@ui.trustEnforcementsList',
    complianceEnforcementsList: '@ui.complianceEnforcementsList',
    craEnforcementsList: '@ui.craEnforcementsList',
    itEnforcementsList: '@ui.itEnforcementsList'
  },

  onBeforeRender: function() {
    var entityTypeId = modelGet(this.model, 'entity.entityTypeId');
    var charterTypeId = modelGet(this.model, 'entity.charterTypeId');
    var entityCategoryId = modelGet(this.model, 'entity.entityCategoryId');
    var hidden = getEntityMainHiddenFields(entityTypeId);

    // For Banks, show 'Is Collective Investment Fund' if BT Charter
    if (entityTypeId === '1' && charterTypeId === '2') {
      hidden.isCollectiveInvestmentFund = false;
    }

    // For Banks, show 'Is FRSOCCFDIC' if TSP Entity Type
    if (entityCategoryId === '1' && (entityTypeId === '3' || entityTypeId === '10')) {
      hidden.isFRSOCCFDIC = false;
    } else {
      hidden.isFRSOCCFDIC = true;
    }

    // For Credit Union show 'Supervisory Committee'
    if (entityCategoryId === '2') {
      hidden.supervisoryCommittee = false;
    } else {
      hidden.supervisoryCommittee = true;
    }

    if ((entityCategoryId === '1' && entityTypeId === '1') || entityCategoryId === '2') {
      hidden.fhlb = false;
    } else {
      hidden.fhlb = true;
    }

    // For Banks, hide Enforcement Action section if HC Entity Type
    if (entityCategoryId === '1' && entityTypeId === '2') {
      hidden.enforcementActionSection = true;
    }

    // For credit unions, hide CRA section
    if (entityCategoryId === '2') {
      hidden.cra = true;
    }

    // For Trusts, hide 'Is Trust Company' if Charter Type is Trust Company
    if (entityCategoryId === '3' && charterTypeId === '21') {
      hidden.isTrustCompany = true;
      hidden.camelsRating = true;
    } else {
      hidden.isTrustCompany = false;
      hidden.camelsRating = false;
    }

    if (entityCategoryId === '3') {
      hidden.isTrust = true;
    } else {
      hidden.isTrust = false;
    }

    if (charterTypeId === '2') {
      hidden.isBankAndTrust = true;
    } else {
      hidden.isBankAndTrust = false;
    }

    this.model.set({ hidden: hidden });

    // Get correct exams to show for exam summary's Last Completed Exam based on examAreaForSummary
    if (!hidden.examSection) {
      var exams = modelGet(this.model, 'exams.exams', {});
      var ssTrustItExams = modelGet(this.model, 'exams.ssTrustItExams', {});
      var compCraExams = modelGet(this.model, 'exams.compCraExams', {});
      var hcExams = modelGet(this.model, 'hcExams', {});
      var isBankHC = entityTypeId === '2';
      var isTSP = _.contains(['3', '12', '23'], entityTypeId);

      if (isBankHC) {
        var lastCompletedHCExam = this.getLastCompletedExam(hcExams);
        this.model.set({ lastCompletedHCExam: lastCompletedHCExam });
      } else if (isTSP) {
        var lastCompletedTSPExam = this.getLastCompletedExam(exams);
        this.model.set({ lastCompletedTSPExam: lastCompletedTSPExam });
      } else {
        var lastCompletedExam;
        if (
          modelGet(this.model, 'entity.entityCategoryId') === '1' ||
          modelGet(this.model, 'entity.entityCategoryId') === '2'
        ) {
          var examsWithRatings = _.filter(ssTrustItExams, function(exam) {
            return !!exam.ss.Composite;
          });
          lastCompletedExam = this.getLastCompletedExam(examsWithRatings);
        } else {
          lastCompletedExam = this.getLastCompletedExam(ssTrustItExams);
        }
        this.model.set({ lastCompletedExam: lastCompletedExam });
      }

      var lastCompletedCompCraExam = this.getLastCompletedExam(compCraExams);
      this.model.set({ lastCompletedCompCraExam: lastCompletedCompCraExam });
    }
  },

  getLastCompletedExam: function(exams) {
    var completedExams = _.filter(exams, function(exam) {
      return exam.transmittalDate && exam.transmittalDate !== '--';
    });

    var lastCompletedExam =
      _.reduce(
        completedExams,
        function(memo, itr) {
          var memoDate = _get(memo, 'transmittalDate');
          var itrDate = _get(itr, 'transmittalDate');
          var latestExam = memoDate > itrDate ? memo : itr;
          return latestExam;
        },
        { transmittalDate: '' }
      ) || {};

    lastCompletedExam.transmittalDate = _get(lastCompletedExam, 'transmittalDate')
      ? moment(_get(lastCompletedExam, 'transmittalDate'), 'YYYY-MM-DD').format('MM/DD/YYYY')
      : '--';
    return lastCompletedExam;
  },

  onBeforeShow: function() {
    var hidden = this.model.get('hidden');
    var entity = this.model.get('entity') || {};
    var entityTypeId = entity.entityTypeId;
    var entityCategoryId = modelGet(this.model, 'entity.entityCategoryId');

    // Set address label for primary address depending on entity type
    if (entity.isTSP) {
      this.ui.corpAddressLabel.show();
    } else if (entityTypeId === '4') {
      this.ui.physAddressLabel.show();
    } else {
      this.ui.ppobLabel.show();
    }

    //Hide non-tsp fields
    toggleForm(this.ui.nonTSPExamInfo, !entity.isTSP);
    toggleForm(this.ui.tspExamInfo, entity.isTSP);

    var isBankHC = entityTypeId === '2';
    toggleForm(this.ui.bankHCExamSummarySection, isBankHC);
    toggleForm(this.ui.nonBankHCExamSummarySection, !isBankHC);

    // Show termination info if FF Entity that's terminated
    if (entity.FFDeptActionId === '3') {
      this.ui.terminationInfo.show();
    }
    // Show address views
    if (!hidden.addressSection) {
      this.showChildView(
        'ppobAddress',
        new AddressDisplayView({
          model: new Backbone.Model(entity.ppob),
          showCounty: true
        })
      );

      if (!hidden.mailingAddress && entity.isMailingSame === '0') {
        this.showChildView(
          'mailingAddress',
          new AddressDisplayView({
            model: new Backbone.Model(entity.mailingAddress),
            showCounty: true
          })
        );
      }
      if (!hidden.billingAddress && entity.isBillingSame === '0') {
        this.showChildView(
          'billingAddress',
          new AddressDisplayView({
            model: new Backbone.Model(entity.billingAddress),
            showCounty: true
          })
        );
      }
    }

    // Enforcements section
    if (!hidden.enforcementActionSection && !entity.isTSP) {
      var gridColumns = [
        {
          name: 'type',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 50,
          editable: false
        },
        {
          name: 'effectiveDate',
          label: 'Effective Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 50,
          editable: false
        }
      ];

      if (hidden.isTrust) {
        this.trustEnforcementsList = new GridView({
          columns: gridColumns,
          collection: new Backbone.Collection(entity.trustEnforcements),
          emptyText: 'No Outstanding Enforcement Actions'
        });
        this.showChildView('trustEnforcementsList', this.trustEnforcementsList);
      } else {
        this.ssEnforcementsList = new GridView({
          columns: gridColumns,
          collection: new Backbone.Collection(entity.ssEnforcements),
          emptyText: 'No Outstanding Safety & Soundness Enforcements'
        });
        this.showChildView('ssEnforcementsList', this.ssEnforcementsList);
      }

      if (entityCategoryId === '1') {
        this.complianceEnforcementsList = new GridView({
          columns: gridColumns,
          collection: new Backbone.Collection(entity.complianceEnforcements),
          emptyText: 'No Outstanding Compliance Enforcements'
        });
        this.showChildView('complianceEnforcementsList', this.complianceEnforcementsList);

        this.craEnforcementsList = new GridView({
          columns: gridColumns,
          collection: new Backbone.Collection(entity.craEnforcements),
          emptyText: 'No Outstanding CRA Enforcements'
        });
        this.showChildView('craEnforcementsList', this.craEnforcementsList);
      }

      if (entityCategoryId === '2') {
        this.itEnforcementsList = new GridView({
          columns: gridColumns,
          collection: new Backbone.Collection(entity.itEnforcements),
          emptyText: 'No Outstanding IT Enforcements'
        });
        this.showChildView('itEnforcementsList', this.itEnforcementsList);
      }
    } else {
      toggleForm(this.ui.enforcementActionSection, false);
    }

    //Show TSP Enforcement Field only when entity is a TSP
    toggleForm(this.ui.tspEnforcementAction, entity.isTSP);
  }
});
