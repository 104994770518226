/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(data) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      SubmitLicenseOfficeManagerChangeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: data.applicationId,
        EffectiveDate: data.effectiveDate,
        EntityID: data.entityId,
        LicenseID: data.licenseId,
        AddressLine1: data.addressLine1,
        AddressLine2: data.addressLine2,
        AddressLine3: data.addressLine3,
        County: data.county,
        City: data.city,
        State: data.state,
        PostalCode: data.postalCode,
        Country: data.country,
        FirstName: data.firstName,
        LastName: data.lastName,
        SSN: data.ssn,
        Title: data.title,
        DOB: data.dob,
        HomePhoneNumber: data.homePhoneNumber,
        OfficePhoneExt: data.OfficePhoneExt,
        CellPhoneNumber: data.cellPhoneNumber,
        Email: data.email,
        Suffix: data.suffix,
        MiddleName: data.middleName,
        IsCertified: data.isCertified ? '1' : '0',
        CertificationName: data.certificationName,
        ApplicationDocuments: (function() {
          if (data.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: data.applicationDocuments
          };
        })()
      }
    },
    blueprint: {
      newApplicationId: '$..NewApplicationID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.newApplicationId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
