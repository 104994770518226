var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"ladda-label\">Finalize Application and Proceed to Cart</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"ladda-label\">Resubmit Application</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"affirmation-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label font-weight-normal\" id=\"InstallmentSellerAffirmationCheckBoxLabel\">\n      <input type=\"checkbox\" id=\"InstallmentSellerAffirmationCheckBox\" name=\"isCertified\" value=\"1\"\n        data-parsley-required=\"true\" />\n      I understand by submitting this Installment Seller Application:\n      <br>\n      I am agreeing to be bound by the following declaration: \"I declare that all of my answers on this License\n      Application are complete, true and correct. I make this declaration subject to the penalties of 18 PA.C.S. § 4904\n      relating to unsworn falsification to authorities.\"\n    </label>\n    <p>I attest that I have read and understand the following (click each for link):</p>\n    <ol>\n      <li>\n        <a href=\"https://www.legis.state.pa.us/cfdocs/legis/LI/consCheck.cfm?txtType=HTM&ttl=12&div=0&chpt=62\"\n          target=\"_blank\">Consumer\n          Credit Code</a>\n      </li>\n      <li>\n        <a href=\"https://www.pa.gov/en/agencies/dobs/non-bank-licensees/mvsf-exam-guidance-and-faqs.html\"\n          target=\"_blank\">Motor Vehicle FAQs</a>\n      </li>\n      <li>\n        <a href=\"https://www.pa.gov/en/agencies/dobs/non-bank-licensees/mvsf-exam-guidance-and-faqs.html\"\n          target=\"_blank\">Motor Vehicle Sales Finance Examination Guide</a>\n      </li>\n    </ol>\n  </div>\n\n  <div class=\"form-group required\">\n    <label><strong>Enter Name Below</strong></label>\n    <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\n      data-parsley-maxlength=\"100\" />\n  </div>\n\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","new",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      </button>\n    </div>\n  </div>\n</form>";
},"useData":true});