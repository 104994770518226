/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var defer = $.Deferred();

  var generalDetails = formData.generalDetails;
  var contractsSection = formData.contractsSection;
  var affirmation = formData.affirmation;

  $.soap({
    body: {
      SubmitCollectorRepossessorRenewalApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        LicenseID: formData.licenseId,
        ApplicationID: formData.applicationId,
        HasCriminalOfficer: generalDetails.hasCriminalOfficer,
        HasCriminalOfficerExplanation: generalDetails.hasCriminalOfficerExplaination,
        HasCriminalOfficerDocumentID: generalDetails.hasCriminalOfficerDocumentId,
        HasOfficerWithAdministrativeAction: generalDetails.hasOfficerWithAdministrativeAction,
        HasOfficerWithAdministrativeActionExplaination: generalDetails.hasOfficerWithAdministrativeActionExplaination,
        HasOfficerWithAdministrativeActionDocumentID: generalDetails.hasOfficerWithAdministrativeActionDocumentId,
        HasUpdatedLicenseContacts: generalDetails.hasUpdatedLicenseContacts,
        HasLegalProceedings: generalDetails.hasLegalProceedings,
        HasLegalProceedingsExplaination: generalDetails.hasLegalProceedingsExplaination,
        HasLegalProceedingsDocumentID: generalDetails.hasLegalProceedingsDocumentId,
        BondCompanyID: generalDetails.bondCompanyId,
        BondNumber: generalDetails.bondNumber,
        BondDocumentID: generalDetails.bondDocumentId,
        EmployeeCancelations: _.map(generalDetails.removedCollectorRepossessorIds, function(id) {
          return { EmployeeID: id };
        }),
        HasCollectedMonies: contractsSection.hasCollectedMonies,
        NumberOfContractsReceived: contractsSection.numberOfContractsReceived,
        ContractsDollarValue: contractsSection.contractsDollarValue,
        ReportingZeroReason: contractsSection.reportingZeroReason,
        HasRepossessMotorVehicles: contractsSection.hasRepossessMotorVehicles,
        TotalNumberVehiclesRepossess: contractsSection.totalNumberVehiclesRepossess,
        NoVehiclesRepossessExplanation: contractsSection.noVehiclesRepossessExplanation,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        ApplicationDocuments: (function() {
          if (generalDetails.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: generalDetails.applicationDocuments
          };
        })(),
        DocumentsToMove: (function() {
          var documentsToMove = _.compact(
            [
              generalDetails.hasCriminalOfficerDocumentId,
              generalDetails.hasOfficerWithAdministrativeActionDocumentId,
              generalDetails.bondDocumentId
            ].concat(generalDetails.applicationDocuments)
          );
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })()
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
