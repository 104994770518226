/**************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var toggleForm = require('common/util/toggleForm');

require('../styles/Calculator.css');

var tmplCalculatorView = require('../templates/CalculatorView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCalculatorView,
  className: 'calculator-view',

  ui: {
    calculatorForm: '.calculator-form',
    bankType: '#bankType',
    totalAssetsSection: '#total-assets-section',
    totalAssets: '#totalAssets',
    assetUnit: '#asset-unit',
    fiduciaryAssetsSection: '#fiduciary-assets-section',
    fiduciaryAssets: '#fiduciaryAssets',
    resultSection: '#result-section',
    result: '#result',
    assetsData: '#assets-data',
    assetsAmount: '#assets-amount',
    fiduciaryData: '#fiduciary-data',
    fiduciaryAmount: '#fiduciary-amount',
    calculate: '#calculate',
    reset: '#reset'
  },

  events: {
    'change @ui.bankType': 'onChangeBankType',
    'change @ui.totalAssets': 'onChangeValidate',
    'change @ui.fiduciaryAssets': 'onChangeValidate',
    'click @ui.calculate': 'onCalculate',
    'click @ui.reset': 'onReset'
  },

  onBeforeShow: function() {
    this.toggleSections();
  },

  onCalculate: function(e) {
    e.preventDefault();
    var bankType = this.ui.bankType.val();

    if (bankType !== '0') {
      var assets = new Number(this.ui.totalAssets.val());
      var fiduciary = new Number(this.ui.fiduciaryAssets.val());
      var aAssessment = new Number(0);
      var fAssessment = new Number(0);

      if (bankType === 'CU') {
        switch (true) {
          case assets <= 24503168:
            aAssessment = 2500;
            break;
          case assets >= 24503169 && assets <= 1115871488:
            aAssessment = 2500 + (assets - 24503168) * 0.0001073975;
            break;
          case assets >= 1115871489 && assets <= 3376610357:
            aAssessment = 119842 + (assets - 1115871488) * 0.0000313025;
            break;
          case assets > 3376610357:
            aAssessment = 190609 + (assets - 3376610357) * 0.00001045;
            break;
        }
      } else if (bankType === 'TC') {
        switch (true) {
          case assets >= 1 && assets <= 20000:
            aAssessment = 6070;
            break;
          case assets >= 20001 && assets <= 100000:
            aAssessment = 6070 + 1000 * (0.000112059 * (assets - 20000));
            break;
          case assets >= 100001 && assets <= 200000:
            aAssessment = 15035 + 1000 * (0.000072836 * (assets - 100000));
            break;
          case assets >= 200001 && assets <= 1000000:
            aAssessment = 22319 + 1000 * (0.000061631 * (assets - 200000));
            break;
          case assets >= 1000001 && assets <= 2000000:
            aAssessment = 71623 + 1000 * (0.000050425 * (assets - 1000000));
            break;
          case assets >= 2000001 && assets <= 6000000:
            aAssessment = 122048 + 1000 * (0.000044822 * (assets - 2000000));
            break;
          case assets >= 6000001 && assets <= 20000000:
            aAssessment = 301338 + 1000 * (0.000038139 * (assets - 6000000));
            break;
          case assets > 20000000:
            aAssessment = 835284 + 1000 * (0.000019409 * (assets - 20000000));
            break;
        }
        switch (true) {
          case fiduciary >= 0 && fiduciary <= 500000:
            fAssessment = 6746;
            break;
          case fiduciary >= 500001 && fiduciary <= 1000000:
            fAssessment = 13492 + 1000 * (fiduciary - 500000);
            break;
          case fiduciary >= 1000001 && fiduciary <= 10000000:
            fAssessment = 13492 + 1000 * 0.000002689 * (fiduciary - 1000000);
            break;
          case fiduciary >= 10000001 && fiduciary <= 100000000:
            fAssessment = 37689 + 1000 * (0.000000449 * (fiduciary - 10000000));
            break;
          case fiduciary > 100000000:
            fAssessment = 78081 + 1000 * (0.0000001425 * (fiduciary - 100000000));
            break;
        }
      } else {
        // Bank
        switch (true) {
          case assets >= 1 && assets <= 20000:
            aAssessment = 6070;
            break;
          case assets >= 20001 && assets <= 100000:
            aAssessment = 6070 + 1000 * (0.000112059 * (assets - 20000));
            break;
          case assets >= 100001 && assets <= 200000:
            aAssessment = 15035 + 1000 * (0.000072836 * (assets - 100000));
            break;
          case assets >= 200001 && assets <= 1000000:
            aAssessment = 22319 + 1000 * (0.000061631 * (assets - 200000));
            break;
          case assets >= 1000001 && assets <= 2000000:
            aAssessment = 71623 + 1000 * (0.000050425 * (assets - 1000000));
            break;
          case assets >= 2000001 && assets <= 6000000:
            aAssessment = 122048 + 1000 * (0.000044822 * (assets - 2000000));
            break;
          case assets >= 6000001 && assets <= 20000000:
            aAssessment = 301338 + 1000 * (0.000038139 * (assets - 6000000));
            break;
          case assets > 20000000:
            aAssessment = 835284 + 1000 * (0.000019409 * (assets - 20000000));
            break;
        }
      }

      toggleForm(this.ui.resultSection, true);

      var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

      if (assets > 0) {
        var adjustAssets = bankType !== 'CU' ? assets * 1000 : assets;
        toggleForm(this.ui.assetsData, true);
        this.ui.assetsAmount.text(formatter.format(adjustAssets));
      } else {
        toggleForm(this.ui.assetsData, false);
      }
      if (bankType === 'TC') {
        toggleForm(this.ui.fiduciaryData, true);
        this.ui.fiduciaryAmount.text(formatter.format(fiduciary * 1000));
      } else {
        toggleForm(this.ui.fiduciaryData, false);
      }

      var resultAssessment = aAssessment * 2 + fAssessment * 2;
      this.ui.result.text(formatter.format(resultAssessment));
    }
  },

  onReset: function(e) {
    e.preventDefault();
    this.toggleSections();
  },

  onChangeBankType: function(e) {
    e.preventDefault();
    this.toggleSections();
    switch (this.ui.bankType.val()) {
      case 'CU':
        this.ui.assetUnit.text('.00');
        break;
      case 'TC':
        this.ui.assetUnit.text(',000.00');
        break;
      case 'BA':
        this.ui.assetUnit.text(',000.00');
        break;
      default:
        this.ui.calculate.prop('disabled', true);
        this.ui.reset.prop('disabled', true);
        break;
    }
  },

  onChangeValidate: function() {
    var ok = true;
    this.validate().fail(function() {
      ok = false;
    });

    if (ok) {
      if (this.ui.bankType.val() === 'TC') {
        if (this.ui.totalAssets.val() && this.ui.fiduciaryAssets.val()) {
          this.ui.calculate.prop('disabled', false);
          this.ui.reset.prop('disabled', false);
        }
      } else {
        if (this.ui.totalAssets.val()) {
          this.ui.calculate.prop('disabled', false);
          this.ui.reset.prop('disabled', false);
        }
      }
    } else {
      this.ui.calculate.prop('disabled', true);
      this.ui.reset.prop('disabled', true);
    }
  },

  validate: function() {
    return this.ui.calculatorForm.parsley().whenValidate();
  },

  toggleSections: function() {
    var bankType = this.ui.bankType.val();
    toggleForm(this.ui.totalAssetsSection, bankType !== '0');
    toggleForm(this.ui.fiduciaryAssetsSection, bankType === 'TC');
    toggleForm(this.ui.resultSection, false);
    this.ui.totalAssets.val('');
    this.ui.fiduciaryAssets.val('');
    this.ui.calculate.prop('disabled', true);
    this.ui.reset.prop('disabled', true);
  }
});

