var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <option value=\"IRTR\">Notice of Intent to Refuse to Renew</option>\n        <option value=\"RTR\">Notice of Refusal to Renew</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <option value=\"ND_TENT_SALE_TEMPLATE\">Tent Sale</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <option value=\"ND_MORTGAGE_ORIGINATOR_INACTIVE_TEMPLATE\">Mortgage Originator Inactive</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.licenseIdentifier : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<h2 class=\"m-t-n\">Generate License Document</h2>\n<form class=\"form-horizontal doc-gen-form m-t-lg\">\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Select Document Type</label>\n    <div class=\"col-sm-6\">\n      <select class=\"form-control document-template-identifier\" name=\"documentTemplateIdentifier\"\n        data-parsley-required=\"true\">\n        <option value=\"\">--</option>\n        <option value=\"ND_15_DAY_ADMINISTRATIVE_ACTION_TEMPLATE\">15 Day Administrative Action</option>\n        <option value=\"ND_CERTIFICATE_TEMPLATE\">License Certificate</option>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.showRTR : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.licenseIdentifier : depth0)) != null ? stack1.licenseTypeId : stack1),"===","15",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.licenseIdentifier : depth0)) != null ? stack1.licenseTypeId : stack1),"===","25",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n  <div class=\"parameters-container\"></div>\n</form>\n<div class=\"row\">\n  <div class=\"col-sm-6 col-sm-offset-3\">\n    <a href=\"#nondep/entity/"
    + alias2(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.licenseIdentifier : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"btn btn-default\"\n      role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\"><span\n        class=\"ladda-label\">Generate Document</span></button>\n  </div>\n</div>\n\n<form class=\"form-horizontal\">\n  <div class=\"form-group m-t-sm\">\n    <label class=\"control-label collapse download-container col-sm-3\">Download Document:</label>\n    <div class=\"col-sm-6\">\n      <div class=\"document-container pull-left\"></div>\n    </div>\n  </div>\n</form>";
},"useData":true});