var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.examIdentifiers : depth0)) != null ? stack1.applicantLegalName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n    <div class=\"exam-action-drop-down\"></div>\n  </div>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<form class=\"form-horizontal end-workflow-form col-sm-9 col-sm-offset-1\">\n  <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group\">\n            <div class=\"col-sm-9 col-sm-offset-3\">\n              <h3 class=\"m-t-n\">Terminate Exam Workflow</h3>\n            </div>\n          </div>\n        </div>\n      </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Justification</label>\n    <div class=\"col-sm-9\">\n      <textarea name=\"justification\" class=\"form-control\" placeholder=\"Justification\" rows=\"5\"\n       data-parsley-required=\"true\"></textarea>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Change Exam Status</label>\n    <div class=\"col-sm-9\">\n      <select name=\"examStatus\" id=\"examStatus\" class=\"form-control\" data-placeholder=\"--\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.examStatuses : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n</form>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\n  <div class=\"row m-b-lg\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <a href=\"#sec/exam/"
    + alias1(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n          <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n            <span class=\"ladda-label\">Submit</span>\n          </button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});