/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetExamIdentifiers = require('../services/GetExamIdentifiers');
var GetAllExamLookups = require('../../common/services/GetAllExamLookups');

var SecExamEndWorkflowView = require('../views/SecExamEndWorkflowView');

module.exports = function(examId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetExamIdentifiers(examId), GetAllExamLookups())
    .done(function(examIdentifiers, examLookups) {
      NavUtils.navigate('#sec/exam/' + examId + '/terminate-workflow');
      Scaffold.content.show(
        new SecExamEndWorkflowView({
          model: new Backbone.Model({
            examIdentifiers: examIdentifiers,
            examStatuses: examLookups.examStatuses,
            examId: examId
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
