var Scaffold = require('common/layout/Scaffold');
var NProgress = require('nprogress');

var CalculatorView = require('../views/CalculatorView.js');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  Scaffold.content.show(new CalculatorView({}));
  NProgress.done();
};

