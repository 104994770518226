/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var defer = $.Deferred();

  var generalDetails = formData.generalDetails;
  var contractsSection = formData.contractsSection;
  var affirmation = formData.affirmation;

  $.soap({
    body: {
      SubmitInstallmentSellerRenewalApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: formData.applicationId,
        HasCriminalOfficer: generalDetails.hasCriminalOfficer,
        HasCriminalOfficerExplaination: generalDetails.hasCriminalOfficerExplaination,
        HasCriminalOfficerDocumentID: generalDetails.hasCriminalOfficerDocumentId,
        HasOfficerWithAdministrativeAction: generalDetails.hasOfficerWithAdministrativeAction,
        HasOfficerWithAdministrativeActionExplaination: generalDetails.hasOfficerWithAdministrativeActionExplaination,
        HasOfficerWithAdministrativeActionDocumentID: generalDetails.hasOfficerWithAdministrativeActionDocumentId,
        HasLegalProceedings: generalDetails.hasLegalProceedings,
        HasLegalProceedingsExplaination: generalDetails.hasLegalProceedingsExplaination,
        HasUpdatedLicenseContacts: generalDetails.hasUpdatedLicenseContacts,
        HasLegalProceedingsDocumentID: generalDetails.hasLegalProceedingsDocumentId,
        ContractsDollarValue: contractsSection.contractsDollarValue,
        ThirdPartyDollarValue: contractsSection.thirdPartyDollarValue,
        ReportingZeroReason: contractsSection.reportingZeroReason,
        ContractsTotalNumber: contractsSection.contractsTotalNumber,
        ThirdPartyTotalNumber: contractsSection.thirdPartyTotalNumber,
        HasContracts: contractsSection.hasContracts ? 1 : 0,
        HasContractsExplaination: contractsSection.hasContractsExplaination,
        HasThirdParty: contractsSection.hasThirdParty ? 1 : 0,
        HasThirdPartyExplaination: contractsSection.hasThirdPartyExplaination,
        IsReportingContracts: contractsSection.isReportingContracts,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        ApplicationDocuments: (function() {
          if (generalDetails.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: generalDetails.applicationDocuments
          };
        })(),
        DocumentsToMove: (function() {
          var documentsToMove = _.compact(
            [
              generalDetails.hasCriminalOfficerDocumentId,
              generalDetails.hasOfficerWithAdministrativeActionDocumentId
            ].concat(generalDetails.applicationDocuments)
          );
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })()
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
